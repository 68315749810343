
import RecordingsWidget from "@/components/recordings/widget/RecordingsWidget"
import { useAccountStore } from "@/stores/account"
import { useCameraStore } from "@/stores/camera"

export default {
  components: {
    RecordingsWidget,
  },
  layout: "widget",
  async asyncData({ query, redirect }) {
    const cameraStore = useCameraStore()
    useAccountStore().isRecordingWidget = true

    await cameraStore.selectCamera(query.camera)
    if (!cameraStore.selectedCamera) {
      redirect("/v2/users/signin")

      return
    }

    return {
      cameraId: query.camera,
      delay: Number.parseInt(query.d || "0"),
      apiId: query.api_id,
      apiKey: query.api_key,
      darkMode: query.dark_mode === "true",
      embedded: query.embedded === "true",
      sidebar: query.sidebar === "true",
    }
  },
}
